import React, { ReactElement, useState } from 'react';
import { Button, Textarea } from 'uikit';
import { CustomModal } from 'techsuite-trl-mrl-calc';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export type Props = {
    isOpen: boolean;
    confirmText?: string;
    onCancel: () => void;
    onConfirm?: () => void;
};

export default ({ isOpen, onCancel }: Props): ReactElement => {
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const { register, errors, handleSubmit } = useForm({
        mode: 'onSubmit',
    });

    const onSubmit = (data: any): any => {
        axios
            .post(`/api/email`, {
                feedback: data.feedback,
            })
            .then(() => {
                setOpen(true);
                setTimeout(() => setOpen(false), 3000);
            })
            .catch(() => {
                setError(true);
                setTimeout(() => setError(false), 3000);
            });
        onCancel();
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert style={{ backgroundColor: '#008577', color: '#ffffff' }} severity="success">
                    Feedback submitted successfully
                </Alert>
            </Snackbar>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={error}
                autoHideDuration={3000}
                onClose={handleClose}
            >
                <Alert style={{ backgroundColor: '#F44237', color: '#ffffff' }} severity="error">
                    Error submitting feedback
                </Alert>
            </Snackbar>
            <CustomModal isOpen={isOpen} onClose={onCancel}>
                <div className="ModalTemplate">
                    <div className="ModalTemplate__title">
                        <h1>Provide Feedback</h1>
                    </div>
                    <div className="ModalTemplate__content">
                        We value your feedback so that we can better improve our product.
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Textarea
                            name="feedback"
                            label="Description"
                            required
                            className="feedback__input"
                            defaultValue=""
                            errors={errors}
                            ref={register}
                        />

                        <div className="ModalTemplate__close">
                            <section className="ModalTemplate__close-buttons">
                                <Button onClick={onCancel} styled="secondary">
                                    Cancel
                                </Button>
                                <Button type="submit" onClick={handleSubmit(onSubmit)}>
                                    Submit Feedback
                                </Button>
                            </section>
                        </div>
                    </form>
                </div>
            </CustomModal>
        </>
    );
};
