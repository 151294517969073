import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
// import { saveState, wipeState } from 'store/middlewares/LocalStorage';
// import Providers from 'components/providers/Providers';
import * as serviceWorker from './serviceWorker';

import '@miletwo/ui/dist/ui.cjs.development.css';
import './assets/sass/root.css';
import 'uikit/dist/uikit.css';

const app = <App />;

// react-axe: when running the application during development, accessibility issues are surfaced directly to the Chrome DevTools console.

if (process.env.NODE_ENV !== 'production') {
    import('react-axe').then((axe) => {
        axe.default(React, ReactDOM, 1000);
        ReactDOM.render(app, document.getElementById('root'));
    });
} else {
    ReactDOM.render(app, document.getElementById('root'));
}

serviceWorker.unregister();
/* Uncomment for offline usage and pwa setup */
// serviceWorker.register({
//     onUpdate: async (registration: ServiceWorkerRegistration) => {
//         // We want to run this code only if we detect a new service worker is
//         // waiting to be activated.
//         // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
//         if (registration && registration.waiting) {
//             await registration.unregister();
//             // Makes Workbox call skipWaiting()
//             registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//             // Once the service worker is unregistered, we can reload the page to let
//             // the browser download a fresh copy of our app (invalidating the cache)
//             window.location.reload();
//         }
//     },
// });

// if (process.env.NODE_ENV === 'development') {
//     // @ts-ignore cache or save the redux Store to local storage
//     global.cache = () => {
//         try {
//             // @ts-ignore TODO: Figure out store typings
//             saveState(store.getState());
//             // eslint-disable-next-line no-console
//             console.log('State Saved to local storage');
//         } catch (error) {
//             // eslint-disable-next-line no-console
//             console.error(error);
//         }
//     };

//     // @ts-ignore wipe the local storage state
//     global.wipe = () => {
//         try {
//             wipeState();
//             // eslint-disable-next-line no-console
//             console.log('state removed from local storage');
//         } catch (error) {
//             // eslint-disable-next-line no-console
//             console.error(error);
//         }
//     };
// }
