import React, { ReactElement, useState } from 'react';
import { Button } from '@material-ui/core';
import FeedbackModal from 'components/feedback-form/FeedbackModal';
import { useWindowSize, TABLET_WIDTH } from 'techsuite-trl-mrl-calc';
import Logo from '../../assets/logos/techgauge-logo.svg';
import FeedbackIcon from '../../assets/logos/review-icon.svg';
import './Header.scss';

const Header = (): ReactElement => {
    const [open, setOpen] = useState(false);
    const [width] = useWindowSize();
    const mobile = width < TABLET_WIDTH;
    const openModal = (): void => setOpen(true);
    const closeModal = (): void => {
        setOpen(false);
    };

    return (
        <div className="AppHeader">
            <img src={Logo} alt="Techgauge Logo" />
            {mobile ? (
                <Button color="secondary" variant="contained" onClick={openModal} disableElevation>
                    <img src={FeedbackIcon} className="feedback__icon" alt="Provide Feedback" />
                </Button>
            ) : (
                <Button color="secondary" variant="contained" onClick={openModal} disableElevation>
                    Provide Feedback
                </Button>
            )}
            <FeedbackModal isOpen={open} onCancel={closeModal} />
        </div>
    );
};

export default Header;
