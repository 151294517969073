import React, { ReactElement } from 'react';
import Logo from '../../assets/logos/m2-logo-white.svg';
import './Footer.scss';

const Footer = (): ReactElement => {
    return (
        <footer>
            <p>Powered by</p>
            <a href="https://www.miletwo.us/" target="_blank" rel="noreferrer">
                <img src={Logo} alt="MileTwo Logo" />
            </a>
        </footer>
    );
};

export default Footer;
