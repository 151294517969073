import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        primary: {
            main: '#9e5c71',
            dark: '#5e3743',
        },
        secondary: {
            main: '#364d6c',
        },
        error: {
            main: '#ed0000',
            contrastText: '#fff7f7',
        },
        success: {
            main: '#228646',
        },
        warning: {
            main: '#eaa118',
        },
    },
    overrides: {
        MuiButton: {
            label: {
                textTransform: 'none',
            },
        },
        MuiSnackbar: {
            root: {
                marginTop: '3rem',
            },
        },
        MuiSvgIcon: {
            root: {
                color: '#ffffff',
            },
        },
    },
});
